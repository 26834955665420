import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import styled from '@emotion/styled';

const Contact = () => {
  return (
    <>
      <SEO title='Contact' />
      <Layout>
        <StyledSection>
          <h1 className='contact-header'>Contact</h1>
          <div className='information'>
            <div className='email'>
              <StyledSmallHeading>
                GENERAL INQUIRIES + MATERIALS LIBRARY
              </StyledSmallHeading>
              <a href='mailto:info@eyrc.com'>info@eyrc.com</a>
            </div>
            <div className='socials'>
              <StyledSmallHeading>SOCIAL</StyledSmallHeading>
              <ul>
                <li>
                  <a href='https://www.facebook.com/EYRCArchitects/'>
                    Facebook
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/EYRCarchitects'>Twitter</a>
                </li>
                <li>
                  <a href='https://www.instagram.com/EYRCarchitects'>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href='https://www.pinterest.com/eyrcarchitects/'>
                    Pinterest
                  </a>
                </li>
                <li>
                  <a href='https://connect.eyrc.com/blog/?__hstc=42132730.1f722389e87e7a9f3f4a80714451ce1b.1611006412499.1611006412499.1611008339049.2&__hssc=42132730.1.1611008339049&__hsfp=3186672725'>
                    Residential Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </StyledSection>
      </Layout>
    </>
  );
};

export default Contact;

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  .contact-header {
    margin: 1.75rem 0 1.5rem;
    font-size: 4.5rem;
    font-weight: normal;

    ::before {
      content: '';
      display: inline-block;
      background-color: #e63e30;
      width: 5.54588%;
      height: 2px;
      margin: 0 2.78746% 0 -6.93961%;
    }

    @media (max-width: 760px) {
      font-size: 3rem;
    }
  }

  .information {
    display: flex;
    flex-direction: row;
    padding-left: 1.9rem;

    div:first-child {
      margin: 0 6.5rem 4rem 0;
    }

    a,
    li {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  @media (max-width: 760px) {
    margin-bottom: 6rem;

    .information {
      display: flex;
      flex-direction: column;
      -webkit-gap: 4rem;
      gap: 4rem;
    }
  }
`;

const StyledSmallHeading = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.7rem;
`;
